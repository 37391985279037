const $everyaction = Symbol('$everyaction')
const EveryActionPlugin = {
  install(app, options = {}) {
    const formId = options.formId
    const fastActionEndpoint = options.fastActionEndpoint || 'https://fastaction.ngpvan.com'
    const databagEndpoint = options.databagEndpoint || 'https://profile.ngpvan.com'
    const databag = options.databag || 'everybody'
    const mobileAutofocus = options.mobileAutofocus || false
    const inlineErrors = options.inlineErrors || true
    const fastactionNoLogin = options.fastactionNoLogin || true

    var sampleCallback = function (args) {}
    if ('nvtag_callbacks' in window) {
      delete window.nvtag_callbacks
    }

    function sampleSegue(args) {
      return args
    }

    window.nvtag_callbacks = {}
    window.nvtag_callbacks.postRender = window.nvtag_callbacks?.postRender || []
    window.nvtag_callbacks.postRender.push(sampleCallback)
    window.nvtag_callbacks.postSegue = window.nvtag_callbacks?.postSegue || []
    window.nvtag_callbacks.postSegue.push(sampleSegue)
    window.nvtag_callbacks.preSegue = window.navtag_callbacks?.preSegue || []
    window.nvtag_callbacks.preSegue.push(sampleSegue)

    if (formId) {
      showForm(formId)
    }

    function showForm(id, elemntId = null) {
      console.log('loaded everyaction')
      if ('nvtag' in window) {
        delete window.nvtag
      }
      const link1 = document.createElement('link')
      link1.setAttribute('id', 'ea-actiontag')
      link1.setAttribute('rel', 'preload')
      link1.setAttribute('href', 'https://static.everyaction.com/ea-actiontag/at.js')
      link1.setAttribute('as', 'script')
      link1.setAttribute('crossorigin', 'anonymous')
      document.head.appendChild(link1)
      const link2 = document.createElement('link')
      link2.setAttribute('id', 'ea-actiontag-css')
      link2.setAttribute('rel', 'preload')
      link2.setAttribute('href', 'https://static.everyaction.com/ea-actiontag/at.min.css')
      link2.setAttribute('as', 'style')
      document.head.appendChild(link2)

      const script = document.createElement('script')
      script.setAttribute('id', 'ea-actiontag-js')
      script.setAttribute('type', 'text/javascript')
      script.setAttribute('crossorigin', 'anonymous')
      script.setAttribute('src', 'https://static.everyaction.com/ea-actiontag/at.js')
      document.head.appendChild(script)

      const formDiv = document.createElement('div')
      formDiv.setAttribute('class', 'ngp-form')
      formDiv.setAttribute('data-form-url', `https://secure.everyaction.com/v1/Forms/${id}`)
      formDiv.setAttribute('data-fastaction-endpoint', fastActionEndpoint)
      formDiv.setAttribute('data-inline-errors', inlineErrors)
      formDiv.setAttribute('data-fastaction-nologin', fastactionNoLogin)
      formDiv.setAttribute('data-databag-endpoint', databagEndpoint)
      formDiv.setAttribute('data-databag', databag)
      formDiv.setAttribute('data-mobile-autofocus', mobileAutofocus)
      if (elemntId) {
        if (typeof elemntId === 'string') {
          document.getElementById(elemntId).appendChild(formDiv)
        } else if (typeof elemntId === 'object') {
          elemntId.appendChild(formDiv)
        }
      } else {
        document.body.appendChild(formDiv)
      }
    }

    function removeFormAndScripts() {
      const formDiv = document.querySelector('.ngp-form')
      if (formDiv) {
        formDiv.remove()
      }
      if (document.getElementById('ea-actiontag')) {
        document.getElementById('ea-actiontag').remove()
      }
      if (document.getElementById('ea-actiontag-css')) {
        document.getElementById('ea-actiontag-css').remove()
      }
      if (document.getElementById('ea-actiontag-js')) {
        document.getElementById('ea-actiontag-js').remove()
      }

      if ('nvtag' in window) {
        delete window.nvtag
      }
    }
    const everyAction = {
      showForm: showForm,
      removeFormAndScripts: removeFormAndScripts,
      addPostRenderCallback: function (callback) {
        window.nvtag_callbacks.postRender = []
        window.nvtag_callbacks.postRender.push(callback)
      },
      addPreSegueCallback: function (callback) {
        window.nvtag_callbacks.preSegue = []
        window.nvtag_callbacks.preSegue.push(callback)
      },
      addOnSubmitCallback: function (callback) {
        window.nvtag_callbacks.onSubmit = []
        window.nvtag_callbacks.onSubmit.push(callback)
      },
      addSegueCallback: function (callback) {
        window.nvtag_callbacks.segue = []
        window.nvtag_callbacks.segue.push(callback)
      },
    }
    app.config.globalProperties.$everyaction = everyAction
    app.provide($everyaction, everyAction)
    // implementation for non-ts use
    app.provide('$everyaction', everyAction)
  },
}

export default EveryActionPlugin
