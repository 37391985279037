<template>
  <div class="mx-auto px-4 sm:px-6">
    <div class="mx-auto max-w-3xl">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GlobalWrapper',
}
</script>
