import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { SET_USER } from './mutation-types'

const STORAGE_HASH = 'Ax4XDbqIDE'
export const STORAGE_KEY = `NCE Go-${STORAGE_HASH}`

const state = {
  user: null,
}

const mutations = {
  [SET_USER]: (state, payload) => {
    state.user = payload
  },
}

const actions = {
  setUser({ commit }, user) {
    commit(SET_USER, user)
  },
}

const getters = {
  isLoggedIn: (state) => {
    return !!state.user
  },
  user: (state) => {
    return state.user
  },
  token: (state) => {
    return state.user ? state.user.token : null
  },
  userGroups: (state) => {
    if (state.user) {
      let usersGroupsArr = []
      for (const key in state.user.groups) {
        usersGroupsArr.push(`${state.user.groups[key].name}`)
      }
      return usersGroupsArr
    } else {
      return null
    }
  },
  isFundraiser: (state) => {
    if (state.user) {
      return state.user.groups.find((group) => group.name === 'Fundraiser') ? true : false
    } else {
      return false
    }
  },
  isCanvassingOrgAdmin: (state) => {
    if (state.user) {
      return state.user.groups.find((group) => group.name === 'Canvassing Org Admin') ? true : false
    } else {
      return false
    }
  },
  isNPOAdmin: (state) => {
    if (state.user) {
      return state.user.groups.find((group) => group.name === 'NPO Admin') ? true : false
    } else {
      return false
    }
  },
  isGivarooStaff: (state) => {
    if (state.user) {
      return state.user.groups.find((group) => group.name === 'Givaroo Staff') ? true : false
    } else {
      return false
    }
  },
}

const store = createStore({
  state,
  mutations,
  actions,
  getters,
  modules: {},
  plugins: [
    createPersistedState({
      key: STORAGE_KEY,
    }),
  ],
})

export default store
