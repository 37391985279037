<template>
  <div class="font-sans antialiased">
    <main>
      <router-view />
    </main>
  </div>
</template>

<script>
export default {
  name: 'App',
  setup() {
    return {}
  },
}
</script>
