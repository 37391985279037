<template>
  <GlobalWrapper>
    <div class="isolate bg-white">
      <main>
        <div class="relative px-6 lg:px-8">
          <div class="mx-auto max-w-3xl pt-20 pb-32 sm:pt-8 text-center">
            <div>
              <div>
                <h1
                  class="sm:text-9xl text-7xl font-display text-primary uppercase [text-shadow:_3px_3px_0_rgb(147_147_147_/_100%)] sm:[text-shadow:_5px_6px_0_rgb(147_147_147_/_100%)] tracking-wide"
                >
                  Givaroo
                </h1>
                <h2 class="sm:text-lg uppercase text-grey tracking-wide">
                  A fundraising processing platform
                </h2>
                <div class="mt-8 flex gap-x-4 justify-center">
                  <RouterLink
                    v-if="!this.isLoggedIn"
                    data-cy="login"
                    to="/login"
                    class="btn--primary"
                  >
                    Log In
                    <span class="text-white pl-1" aria-hidden="true">&rarr;</span>
                  </RouterLink>
                  <RouterLink v-else to="/campaigns" class="inline-block w-auto btn--primary">
                    View Dashboard{{ ' ' }}
                    <span class="text-white pl-1" aria-hidden="true">&rarr;</span>
                  </RouterLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </GlobalWrapper>
</template>

<script>
import GlobalWrapper from '@/components/GlobalWrapper.vue'
import store from '@/store/'
import { computed } from 'vue'

export default {
  components: {
    GlobalWrapper,
  },
  setup() {
    const userGroups = store.getters.userGroups || false
    return {
      isLoggedIn: computed(() => store.getters.isLoggedIn),
      userGroups,
    }
  },
}
</script>
