import { createApp } from 'vue'
import * as Sentry from '@sentry/vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './main.css'
import VueCreditCardValidation from 'vue-credit-card-validation'
import EveryActionPlugin from './plugins/every-action/every-action'

import { checkEnvFromWindow } from './utils/misc'

const app = createApp(App)

const dsn = {
  review:
    'https://f6fedc9a2467e23859486c24a8874293@o4506038947610624.ingest.us.sentry.io/4507725391396864',
  staging:
    'https://ab2225d7e6258e38748e84d9f74b4cb4@o4506038947610624.ingest.us.sentry.io/4507725388447744',
  production:
    'https://c19b6b762b1eeb5b2f6a14ff21cee15c@o4506038947610624.ingest.us.sentry.io/4506038947610624',
}

Sentry.init({
  app,
  environment: checkEnvFromWindow(),
  dsn: dsn[checkEnvFromWindow()],
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'https://givaroo.org',
    'https://nce-go-staging.herokuapp.com',
    'https://nce-go-production.herokuapp.com',
  ],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})
Sentry.captureException(new Error('This is a test error'))

app.use(store).use(router).use(EveryActionPlugin).use(VueCreditCardValidation).mount('#app')
