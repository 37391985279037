export const prReviewRegex = /nce-go-pr-\d+|localhost/
export const stagingRegex = /nce-go-staging-27d54e6a2bb6/

export function checkEnvFromWindow() {
  if (window.location.hostname.match(prReviewRegex)) {
    return 'review'
  } else if (window.location.hostname.match(stagingRegex)) {
    return 'staging'
  } else {
    return 'production'
  }
}
